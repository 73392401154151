
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
* {
	padding: 0;
	margin: 0;
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	overflow-x: hidden;
	color: #f5f6fa;
}

:root {
	--black-color: #17252A;
	--white-color: #FEFFFF;
	--poor-white-color: #DEF2F1;
  }

#app {
	height: auto;
	width: 100%;
	overflow-x: hidden;
}
.body::-webkit-scrollbar {
	display: none;
}

body {
	background-color: var(--black-color);
	overflow-x: hidden;
	-moz-user-select: none;
	-khtml-user-select: none;
	user-select: none;
}

.mainViewContainer {
	transition: all 1s ease-in-out;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
    width: 100%;
    height: 100%;
	overflow: hidden;
	opacity: 1;
}
.mainViewContainer::-webkit-scrollbar {
	display: none;
}

.mainViewContainerDisapire {
	transition: all 1s ease-in-out;
	height: 0vh !important;
	opacity: 0 !important;
}
.sideContent {
	display: none;
}
.sideContentVisible {
	display: block !important;
}

.cicleBlock {
	width: 100%;
	z-index: 2;
	position: absolute;
	overflow-y: hidden;
	overflow-x: hidden;
}

.mainStage {
	position: absolute;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	transition: all .5s ease;
}

/* @media (hover: hover) and (pointer: fine) {
	.mainStage:hover .mainStageText{
	transition: all .5s ease;
	font-size: 90px;
	}
	.mainStage:hover .mainStageLine{
		transition: all .5s ease;
		height: 8px;

	}
	.mainStage:hover .mainStageSideText{
		transition: all .5s ease;
		font-size: 30px;
	}
  } */
  .mainStage:hover {
	  	transition: all .5 ease;
		transform: scale(1.2, 1.2);
  }

	.mainStageText {
    font-family: 'Syncopate', sans-serif;
    color: transparent;
    -webkit-text-stroke: 2px var(--black-color);
    font-weight: 900;
    font-size: 1.8em;
}

.mainStageLine {
	width: 100%;
	height: 6px;
	background-color: var(--black-color);
	border-radius: 30px;
	border: none;
	margin-top: 20px;
	margin: 20px;
	transition: all 1 ease-in-out;
}
.mainStageSideText {
	font-weight: bold;
	color: var(--black-color);
	font-family: 'Syncopate', sans-serif;
}

.navbar-block {
	width: 100%;
	height: 75px;
	position: fixed;
	background-color: #1e272e;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 10px;
}
.header-container {
	width: fit-content;
	height: 100%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.header-container h2 {
	font-family: fieldwork, sans-serif;
	font-weight: bold;
}

.ln-dropdown-select {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100px;
	height: 100%;
}
.dropdown-select {
	width: 45px;
	height: 30px;
	background-color: transparent;
	border: none;
	outline: none;
}
.navbar-logo {
	width: 75px;
	height: 65px;
	z-index: 3123;
	overflow: hidden;
}

.projectsSectionBlock {
	width: 100%;
	margin-top: 10vh;
	color: #d2dae2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	padding: 4% 8%;
}
.projectSectionHeader {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	padding-top: 20px;
}
.contactSectionHeader {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	padding-top: 20px;
	margin-bottom: 80px;
}
.headerUnderline {
	width: 125px;
	height: 0.3px;
	background-color: var(--white-color);
	border-radius: 30px;
	border: none;
	color: var(--white-color);
	margin-top: 3px;
}
.projectsContainer {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	overflow: hidden;
	/* padding-bottom: 200px; */
}
.projectCom {
	display: flex;
	flex-direction: column;
	min-width: 350px;
	max-width: 21vw;
	margin-bottom: 30px;
	cursor: pointer;
	padding: 0 20px;
}
.projectCom:hover {
	transition: all .3s ease-in-out;
	transform: scale(1.1, 1.1) !important;
}
.project-link {
	text-decoration: none;
}
.projectMiniView {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	transition: all .3s ease-in-out;

}
.projectScreenShot {
	width: 400px;
	height: 200px;
}
.projectName {
	margin-top: 13px;
}
.projectNameText {
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	text-transform: uppercase;
	color: var(--poor-white-color);
	opacity: 0.6;
}
.projectNumber {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.projectNumberText {
	font-family: 'Lato', sans-serif;
	font-size: 30px;
	margin-bottom: 15px;
	color: var(--poor-white-color);
}
.motoSection {
	width: 100%;
	height: 100vh;
	background-color: var(--black-color);	
	display: flex;
	justify-content: center;
}
.motoSection-container {
	max-width: 80vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.headerTextBlock {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-transform: uppercase;
	padding-left: 20px;
	margin-top: 60px;
	margin-bottom: -60px;
}
.headerText {
	font-family: fieldwork, sans-serif;
	font-size: 5.5vw;
	width: 100%;
	font-weight: bold;
}
.underHeaderTextBlock {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

}
.underHeaderTextBlockWrapper {
	width: 70%;
}
.underHeaderText {
	font-family: 'Syncopate', sans-serif;
	font-size: 1.8vw;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 15px;
	line-height: 1.3;
}

.technology-section {
	width: 100%;
	background-color: var(--black-color);
	display: flex;
	flex-direction: row;
}
.technology-section-header {
	width: 10%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.technology-header-block {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 280px;
	transform: rotate(270deg);
	background-color: var(--black-color);
	font-family: 'Syncopate', sans-serif;
	font-size: 26px;
	font-weight: bold;
}

.technology-block {
	width: 300px;
	height: 300px;
	overflow-y: hidden;
	perspective: 500px;
}

.technology-content {
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	transition: 1s;
  	transform-style: preserve-3d;
	transition: 1s;
}

.technology-content-block {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	opacity: 1;
	transition: all .5s ease-in-out;
}

.rotatedCard {
	transform: rotateY( 180deg );
	transition: 1s;
}
.rotatedCardBlock {
	transition: all .5s ease-in-out;
	opacity: 0;
}
.rotatedCardBack {
	transition: all .5s ease-in;
	position: absolute;
	top: 0;
	opacity: 1 !important;
	z-index: 1;
}

@media (hover: hover) and (pointer: fine) {
	.technology-block:hover .technology-content{
	transform: rotateY( 180deg );
	transition: 1s;
	}

	.technology-content:hover > .technology-content-block {

		transition: all .5s ease-in-out;
		opacity: 0;
	}
	.technology-content:hover > .back-card {
		transition: all .5s ease-in;
		position: absolute;
		top: 0;
		opacity: 1;
		z-index: 1;
	}
  }
 
.technology-content > .back-card {
	transform: rotateY( -180deg );
	transition: 1s;
} 

.back-card {
	z-index: -1000;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all .5s ease-in;
}
.back-card-header {
	width: 100%;
	height: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.back-card-content {
	width: 100%;
	height: 70%;
	display: flex;
	justify-content: center;
	text-align: center;
}


.technology-logo-img {
	width: 100px;
	height: 100px;
	font-size: 20px;
}
.technology-logo {
	width: 100%;
	height: 40%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.technology-desc {
	width: 95%;
	height: 40%;
	overflow-wrap: normal;
	text-align: center;
	display: flex;
	justify-content: center;	
}
.technology-desc-text {
	font-size: 13px;
	font-weight: bold;
}
  
  @-webkit-keyframes scroll {
	0% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	}
	100% {
	  -webkit-transform: translateX(calc(-250px * 10));
			  transform: translateX(calc(-250px * 10));
	}
  }

  @keyframes scroll {
	0% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	}
	100% {
	  -webkit-transform: translateX(calc(-250px * 10));
			  transform: translateX(calc(-250px * 10));
	}
  }

  @keyframes scroll-serv {
	0% {
		-webkit-transform: translateX(0);
				transform: translateX(0);
	  }
	  100% {
		-webkit-transform: translateX(calc(-250px * 7));
				transform: translateX(calc(-250px * 7));
	  }
  }
  @-webkit-keyframes scroll-serv {
	0% {
	  -webkit-transform: translateX(0);
			  transform: translateX(0);
	}
	100% {
	  -webkit-transform: translateX(calc(-250px * 7));
			  transform: translateX(calc(-250px * 7));
	}
  }

  .slider {
	box-shadow: 0 10px 20px -3px rgba(0, 0, 0, 0.125);
	height: 300px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 90%;
  }
  .slider-services {
	box-shadow: 0 10px 20px -3px rgba(0, 0, 0, 0.125);
	height: 300px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 90%;
  }

  .slider .slide-track {
	-webkit-animation: scroll 30s linear infinite;
			animation: scroll 30s linear infinite;
	display: flex;
	width: calc(250px * 20);
  }
  .slider-services .slide-track-ser {
	-webkit-animation: scroll-serv 40s linear infinite;
			animation: scroll-serv 40s linear infinite;
	display: flex;
	width: calc(250px * 14);
  }
  .slider .slide-track:hover {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
  }
  .slider-services .slide-track-ser:hover {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
  }
  .slider .slide {
	height: 100px;
	width: 250px;
  }

.contact-section {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
}
.footer-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: center;
}
.up-arrow-container {
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: flex-end;
}
.up-arrow-block {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	/* width: 100%; */
	flex-direction: column;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	margin-right: 20px;
}

.up-arrow-block img {
	cursor: pointer;
}
.button-block {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

}
.button-block img {
	/* margin-top: -4px; */
	width: 30px;
	height: 30px;
}
.button-block div {
	display: flex;
	padding: 0;
	text-align: center;
	justify-content: center;
	align-items: center;
}
.button-block span {
	margin-top: 7px;
}
.form-section {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--white-color);
	transform: translateX(-100%);
	transition: all 0.54s ease-in-out;
}


.form-section-open {
	transform: translateX(0%);
	transition: all 0.45s ease-in-out;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	padding: 20px;
}
.form-container button {
	margin-top: 50px;
}
.go-back-button {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 20px;
	overflow: hidden;
}
.go-back-button img {
	height: 40px;
	width: 40px;
	cursor: pointer;
}

.form-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4% 0%;
}

.form-container form {
	width: 70%;
	display: flex;
	flex-direction: column;
}

.form-container form input {
	height: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	color: #17252A;
	font-size: 50px;
	border: none;
	border-bottom: 3px #17252A solid;
	outline: none;
}
.form-container form textarea {
	height: 180px;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #17252A;
	font-size: 50px;
	border: none;
	border-bottom: 3px #17252A solid;
	outline: none;
	resize: none;
	transition: all 0.50s ease-in-out;

}

.go-back-button img:hover {
	transition: all 0.50s ease-in-out;
	transform: rotateZ(180deg);
}

.get-in-touch-button {
	height: 80px;
	width: 200px;
	border: none;
	overflow: hidden;
	position: relative;
	background: linear-gradient(-90deg, #2f3640, #353b48, #222f3e, #2f3542);
	background-size: 400% 400%;
	animation: alternate gradient 7s ease infinite;
	cursor: pointer;
	border-radius: 1px;
}

.contact-button {
	border: none;
	overflow: hidden;
	position: relative;
	background: linear-gradient(-90deg, #2f3640, #353b48, #222f3e, #2f3542);
	background-size: 400% 400%;
	animation: alternate gradient 7s ease infinite;
	cursor: pointer;

}

.contact-button-invers {
	background: var(--white-color);
	border: 2px solid #2f3640;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.contact-button-invers span{
	font-family: 'Syncopate', sans-serif;
	font-size: 30px;
	letter-spacing: 4px;
	font-weight: bold;
	color: #2f3640;
	transition: all 0.75s ease-in-out;
}
.contact-button span {
	font-family: 'Syncopate', sans-serif;
	font-size: 30px;
	letter-spacing: 4px;
	font-weight: bold;
	color: var(--white-color);
	transition: all 0.75s ease-in-out;
}
.contact-button-invers::before {
	content: '';
	width: 100%;
	height: 100%;
	background: #2f3640;
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(-120%);
	transition: all 0.90s ease-in-out;
}

.contact-button::before{
	content: '';
	width: 100%;
	height: 100%;
	background: var(--white-color);
	position: absolute;
	top: 0;
	left: 0;
	transform: translateX(-120%);
	transition: all 0.90s ease-in-out;
}
.contact-button:hover {
	transition: all 0.90s ease-in-out;
	background: transparent;
}
.contact-button-invers:hover {
	transition: all 0.90s ease-in-out;
	background: transparent;
}
.contact-button-invers:hover::before {
		transition: all 0.90s ease-in-out;
		transform: translateX(0%);
		color: #2f3640;
		z-index: -1;
}
.contact-button:hover::before{
		transition: all 0.90s ease-in-out;
		transform: translateX(0%);
		color: #2f3640;
		z-index: -1;
}
.contact-button:hover span{
	transition: all 0.75s ease-in-out;
	color: #2f3640;
}
.contact-button-invers:hover span {
	transition: all 0.75s ease-in-out;
	color: var(--white-color);
}


.contact-section p {
	font-family: sans-serif;
	font-size: 20px;
	margin-top: 30px;
}

.contact-section a {
	font-family: sans-serif;
	font-size: 20px;
	margin-top: 15px;
}
  .slide-block-text {
	  display: flex;
	  justify-content: center;
	  text-align: center;
	  width: 100%;
	  height: 20%;
  }
  .slide-block-text h3 {
	  font-weight: bold;
  }
 
.project-details-screen {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	background: #17252A;
	z-index: 2;
	overflow: hidden;
}
.project-main-view {
	position: relative;
	width: 100%;
	height: 110vh;
	display: flex;
	align-items: center;
	overflow: hidden;
}
.project-details-title {
	margin-left: 21%;
	z-index: 1;

}
.project-details-title h3 {
	font-family: 'Syncopate', sans-serif;
	font-weight: bold;
	letter-spacing: 2px;
	font-size: 30px;
}
.project-details-title h1 {
	font-family: fieldwork, sans-serif;
	font-weight: bold;
	letter-spacing: 6px;
	font-size: 70px;
	margin-left: 15px;
	-webkit-text-stroke: 2px #17252a;
	-webkit-text-stroke: 2px var(--black-color);
	font-weight: bolder;
}
.project-screen-shot {
	position: absolute;
	width: 70%;
	max-width: 1300px;
	/* height: 80vh; */
	top: 10%;
	left: 30%;
	overflow: hidden;
}
.project-screen-shot img {
	width: 100%;
	height: 100%;
}

.project-details-info {
	width: 100%;
	min-height: 70vh;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-wrap: wrap;
}
.project-details-description {
	width: 350px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}
.project-details-description p, .project-details-credit  p {
	font-family: fieldwork, sans-serif;
	line-height: 1.5;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 30px;
}
.project-details-description span, .project-details-credit span {
	font-family: 'Syncopate', sans-serif;
	font-size: 15px;
	font-weight: bold;
	line-height: 1.5;

}
.down-arrow-block {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: transparent;
	position: absolute;
	top: 80%; 
	z-index: 2131;
	width: 100px;
	height: 130px;
	cursor: pointer;
}
.down-arrow-block img {
	width: 50px;
	height: 40px;
}
.down-arrow-block p {
	font-weight: bold;
	text-transform: uppercase;
}


.project-details-credit {
	width: 350px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	/* height: 200px; */
}

.project-screen-shots {
	width: 100%;
	margin-bottom: 40px;
}
.screenShot {
	/* width: 100%;
	height: 600px; */
	margin-top: 50px;
	overflow: hidden;
}
.mobile-screenshots {
	width: 100%;
	height: 600px;
	margin-top: 50px;
	display: flex;
	overflow: hidden;

}
.mobile-screenshots-block {
	width: 50%;
	height: auto;
	display: flex;
	justify-content: space-evenly;
	padding-top: 40px;
	padding-bottom: 40px;
}
.first-mob {
	align-self: flex-end;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	overflow: hidden;
}
.first-mob img {
	width: 238px;
	height: 460px;
}
.second-mob img {
	width: 238px;
	height: 460px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.second-mob {
	overflow: hidden;
}
.alignedStart {
	display: flex;
	justify-content: flex-start;
	padding-left: 40px;
}
.alignedEnd {
	display: flex;
	justify-content: flex-end;
	padding-right: 40px;
}
.screenShot img {
	/* width: 60%;
	max-width: 1234px;
	height: 100%;
	text-align: end; */
}
@media only screen and (min-width: 768px) {
	.screenShot img {
	width: 90%;
}
}

.background-polygon {
	position: absolute;
	width: 100%;
	height: 700px;
	overflow: hidden;
}
.fade-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	/* border: 1px solid pink; */
}

svg {
	height: 700px;
	width: 120%;
	z-index: -1;
	display: block;
	position: absolute;
	left: -370px;
	padding: 0;
  }

  .rotatedSvg {
	transform: rotateY(-180deg);
	left: 0;
	z-index: -1;
  }


	.st48,
	.st52,
	.st50{
	  animation: drift-right-up 80s alternate;
	}
	.st33,
	.st51 {
	  animation: drift-right-up 75s alternate;
	}
	.st49,
	.st42 {
	  animation: drift-right-up 65s alternate;
	}
	
  
  
	.st11,
	.st56 {
	  animation: drift-down 70s alternate;
	}
	.st53,
	.st54{
	  animation: drift-right 195s alternate;
	}
	.st55,
	.st36,
	.st33 {
	  animation: drift-down 115s alternate;
	}
	.st51 {
	  animation: drift-down-right 105s alternate;
	}

  
	.st40,
	.st43,
	.st33{
	  animation: drift-right 115s;
	}
	.st41,
	.st44 {
	  animation: drift-right-up 105s;
	}
	.st42,
	.st45,
	.st46,
	.st55,
	.st47{
	  animation: drift-right 135s;
	}

	.st54,
	.st58,
	.st59{
	  animation: drift-right 55s;
	}
	.st57,
	.st51 {
	  animation: drift-right-up 40s;
	}  






@keyframes drift-right {
	100% {
	  transform: translate(2000px, -20px);
	}
  }
  
  @keyframes drift-right-up {
	100% {
	  transform: translate(2000px, -90px);
	}
  }
  
  @keyframes drift-up {
	100% {
	  transform: translate(10px, -1450px);
	}
  }
  
  @keyframes drift-up-right {
	100% {
	  transform: translate(175px, -1550px);
	}
  }
  
  @keyframes drift-down {
	100% {
	  transform: translate(2000px, 200px);
	}
  }
  
  @keyframes drift-down-right {
	100% {
	  transform: translate(1450px, 320px);
	}
  }


.st0{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st1{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st2{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st3{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st4{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st5{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st6{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st7{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st8{opacity:0.5;fill:none;stroke:#c4f1f1;stroke-miterlimit:10;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st9{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st10{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st11{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st12{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st13{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st14{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st15{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st16{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st17{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st18{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st19{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st20{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st21{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st22{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st23{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st24{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st25{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st26{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st27{opacity:0.2;fill: none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st28{opacity:5.000000e-02;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st29{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st30{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st31{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st32{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st33{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
    .st0{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st1{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st2{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st3{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st4{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st5{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st6{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st7{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st8{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st9{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st10{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st11{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st12{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st13{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st14{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st15{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st16{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st17{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st18{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st19{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st20{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st21{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st22{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st23{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st24{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st25{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st26{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st27{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st28{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st29{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st30{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st31{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st32{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st33{opacity:0.3;fill:none;stroke:#dbfdfd;stroke-miterlimit:10;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st34{opacity:5.000000e-02;fill:none;stroke:#c7fcfd;stroke-miterlimit:10;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st35{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st36{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st37{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st38{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st39{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st40{opacity:0.35;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}
	.st41{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st42{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st43{opacity:0.34;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st44{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st45{opacity:0.35;fill:none;stroke:#d2feff;stroke-miterlimit:10;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st46{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st47{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st48{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st49{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st50{opacity:0.35;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st51{opacity:0.33;fill:none;stroke:#fcfcfc;stroke-miterlimit:10;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st52{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st53{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st54{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st55{opacity:0.3;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st56{opacity:0.5;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st57{opacity:0.35;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st58{opacity:0.34;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px} 
	.st59{opacity:0.37;fill:none;stroke:#d9fbfc;stroke-miterlimit:10; stroke-width: 3px}



@media (max-width: 1900px) {
	.mainStageText {
		 font-size: 54px;
	 }
   .mainStageSideText {
	   font-size: 18px;
   }
   
 }
 @media (min-width: 1900px) {
	.projectsSectionBlock {
		padding: 4% 17%;
	}
 }
 @media (min-width: 2600px) {
	.projectsSectionBlock {
		padding: 4% 25%;
	}
 }

 @media (max-width: 1024px) {
	.project-screen-shot {
		width: 100vw;
		height: fit-content;
		margin: 0;
		left: 0%;
	}
	.screenShot {
		height: auto;
	}
 }

  @media (max-width: 1000px) {
	.mainStageText {
		font-size: 1.8em;

			font-family: "Syncopate",sans-serif;
			color: var(--black-color);
			-webkit-text-stroke: 0px var(--black-color);
			font-weight: 900;
	 }
   .mainStageSideText {
	   font-size: 18px;
   }
   .form-container form input {
	   font-size: 40px;
   }
   .form-container form textarea {
	   font-size: 40px;
   }
	   
 }
 @media (max-width: 900px) {
	.mobile-screenshots-block {
		width: 60%;
	}
	.alignedStart {
		justify-content: center;
		padding: 0;
	}
	.alignedEnd {
		justify-content: center;
		padding: 0;
	}
	.form-container form input {
		font-size: 30px;
	}
	.form-container form textarea {
		font-size: 30px;
	}
	.project-screen-shot {
		width: 100vw;
		height: fit-content;
		margin: 0;
		left: 0%;
	}
	.screenShot {
		height: auto;
	}
 }
 @media (max-width: 746px) {
	.mobile-screenshots-block {
		width: 80%;
	}
	.screenShot {
		height: 400px;
		margin: 40px auto;

	}
	.screenShot img {
		width: 90%;
	}
	.fade-container {
		height: 101vh;
	}
 }
 @media (max-width: 560px) {
	.mobile-screenshots-block {
		width: 100%;
		justify-content: space-around;
	}
	.alignedEnd {
		padding: 0;
	}
	.alignedStart {
		padding: 0;
	}
	.screenShot {
		height: 300px;
		margin: 40px auto;

	}
	.screenShot img {
		width: 90%;
	}
	
 }

  @media (max-width: 625px) {
 	.mainStageText {
    font-size: 1.8em;

			font-family: "Syncopate",sans-serif;
			color: var(--black-color);
			-webkit-text-stroke: 0px var(--black-color);
			font-weight: 900;
	  }
	.mainStageSideText {
		font-size: 13px;
	}
	.project-screen-shot {
		height: 70vh;
	}
	.project-main-view {
		height: 90vh;
	}
	.form-container form input {
		font-size: 20px;
	}
	.form-container form textarea {
		font-size: 20px;
	}
		
  }
  @media (max-width: 500px) {
	.headerText {
		font-size: 9vw;
	}
	.underHeaderText {
		font-size: 4vw;
	}
	.underHeaderTextBlockWrapper {
	  line-height: 20px;
	}
	.motoSection {
		height: 100vh;
	}
	/* .projectsContainer {
		width: 100%;
		padding-bottom: 20px;
	} */
	.project-screen-shot {
		height: 50vh;
	}
	.go-back-button img {
		width: 25px;
		height: 25px;
	}
	.fade-container {
		height: 105vh;
	}
}
  @media (max-width: 400px) {
	.mainStageText {
    font-size: 1.8em;

		 font-family: "Syncopate",sans-serif;
		 color: var(--black-color);
		 -webkit-text-stroke: 0px var(--black-color);
		 font-weight: 900;
	 }
 }
 @media (max-width: 450px) {
	.mainStageText {
    font-size: 1.8em;

		 font-family: "Syncopate",sans-serif;
		 color: var(--black-color);
		 -webkit-text-stroke: 0px var(--black-color);
		 font-weight: 900;

	 }
	 .mainStageLine {
		width: 180px;
	}
	.mainStageSideText {
		font-size: 13px;
	}
	.project-screen-shot {
		height: 50vh;
	}
	.project-main-view {
		height: 70vh;
	}
	.screenShot {
		height: 200px;
		width: 90%;
		margin: 40px auto;
	}
 }
 @media (max-width: 438px) {
	.first-mob {
		width: 170px;
		height: 360px;
	}
	.second-mob {
		width: 170px;
		height: 360px;
	}
	.first-mob img {
		width: 100%;
		height: 100%;
	}
	.second-mob img {
		width: 100%;
		height: 100%;
	}
	.form-container form input {
		font-size: 17px;
	}
	.form-container form textarea {
		font-size: 17px;
	}
	.technology-block {
		width: 150px;
	}
	.project-details-title {
		margin-left: 15px;
	}
	.project-details-title > h1 {
		font-size: 40px;
	}
	.down-arrow-block {
		top: 75%;
	} 

	.project-details-title > h3 {
		font-size: 20px;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.project-screen-shot {
		display: none;
	}
	@-webkit-keyframes scroll {
		0% {
		  -webkit-transform: translateX(0);
				  transform: translateX(0);
		}
		100% {
		  -webkit-transform: translateX(calc(-150px * 7));
				  transform: translateX(calc(-150px * 7));
		}
	  }
	  .slide-block-text {
		  font-size: 13px;
	  }
}
@media (max-width: 362px) {
	.first-mob {
		width: 150px;
		height: 340px;
	}
	.second-mob {
		width: 150px;
		height: 340px;
	}
	.first-mob img {
		width: 100%;
		height: 100%;
	}
	.second-mob img {
		width: 100%;
		height: 100%;
	}
	.form-container form input {
		font-size: 15px;
	}
	.form-container form textarea {
		font-size: 15px;
	}
}
 @media (max-width: 395px) {
		.mainStageText {
			font-size: 1.8em;

			 font-family: "Syncopate",sans-serif;
			 color: var(--black-color);
			 -webkit-text-stroke: 0px var(--black-color);
			 font-weight: 900;

		 }
		 .mainStageLine {
			 width: 180px;
		 }
		 .mainStageSideText {
			 font-size: 15px;
		 }
}
@media (max-width: 376px) {
	.mainStageText {
		 font-size: 23px;
		 color: var(--black-color);

	 }
	 .mainStageLine {
		 width: 150px;
	 }
	 .mainStageSideText {
		 font-size: 12px;
	 }
	 .project-screen-shot {
		height: 40vh;
	}
	.project-details-title {
		margin-left: 15px;
	}
	.project-details-title > h1 {
		font-size: 50px;
	}

	.project-details-title > h3 {
		font-size: 20px;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.project-screen-shot {
		display: none;
	}
}
@media (max-width: 344px) {
	.project-screen-shot {
		height: 30vh;
	}
	.project-main-view {
		height: 39vh;
	}
	.project-details-title {
		margin-left: 15px;
	}
	.project-details-title > h1 {
		font-size: 40px;
	}

	.project-details-title > h3 {
		font-size: 20px;
		margin-bottom: 10px;
		margin-top: 10px;
	}
	.project-screen-shot {
		display: none;
	}
	.down-arrow-block {
		top: 70%;
	} 
}
@media (max-width: 290px) {
	.project-screen-shot {
		height: 30vh;
		left: 20%;
		/* width: 90%; */

	}
	.project-main-view {
		height: 39vh;
	}
	.project-details-title {
		margin-left: 5px;
	}
	.project-screen-shot {
		display: none;
	}
}

@media (max-height: 524px) {
	.mainStageText {
		font-size: 30px;
		font-family: "Syncopate",sans-serif;
		color: var(--black-color);
		-webkit-text-stroke: 0px var(--black-color);
		font-weight: 900;

	}
  .mainStageSideText {
	  font-size: 11px;
  }
  
 }
 @media (max-height: 400px) {
	.mainStageText {
		font-size: 20px;
		font-family: "Syncopate",sans-serif;
		color: var(--black-color);
		-webkit-text-stroke: 0px var(--black-color);
		font-weight: 900;

	}
  .mainStageSideText {
	  font-size: 10px;
  }
 }
 @media (max-height: 308px) {
	.mainStageText {
		font-size: 20px;
		font-family: "Syncopate",sans-serif;
		color: var(--black-color);
		-webkit-text-stroke: 0px var(--black-color);
		font-weight: 900;

	}
	.mainStageLine {
		width: 120px;
		margin: 5px;
	}
  .mainStageSideText {
	  font-size: 8px;
  }
 }


 @keyframes fadeIn {
	0% {
	  opacity: 0;
	  top: -100px;
	}
	100% {
	  opacity: 1;
	  top: 0px;
	}
  }
  
  @keyframes fadeOut {
	0% {
	  opacity: 1;
	  top: 0px;
	}
	100% {
	  opacity: 0;
	  top: -100px;
	}
  }
  .facebook-link img {
		margin-bottom: -5px;
	}

  .fade-container::-webkit-scrollbar {
	display: none;
  }

  .box {
	align-self: flex-end;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	height: 100%;
	margin: 0 auto 0 auto;
	transform-origin: bottom;
	width: 100%;
}
.bounce-6 {
	animation-name: bounce;
	animation-timing-function: ease;
}
@keyframes bounce {
	0%   { transform: scale(1,1)      translateY(0); }
	10%  { transform: scale(1.1,.9)   translateY(0); }
	30%  { transform: scale(.9,1.1)   translateY(-30px); }
	50%  { transform: scale(1.05,.95) translateY(0); }
	57%  { transform: scale(1,1)      translateY(-7px); }
	64%  { transform: scale(1,1)      translateY(0); }
	100% { transform: scale(1,1)      translateY(0); }
}